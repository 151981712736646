import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-stele-works"
    }}>{`How Stele Works`}</h1>
    <p>{`Stele is a compile-time translation library that can also operate at runtime.
Stele works by taking the code you write and transforming it in to an ICU
message. Then Stele can take ICU messages and convert that back in to run time
code. This, however, gives Stele some limitations. I feel that understanding how
Stele works can make it easier to understand why you have to make some small
tweaks to your code.`}</p>
    <h3 {...{
      "id": "why-it-has-to-be-this-way"
    }}>{`Why it has to be this way?`}</h3>
    <p><a parentName="p" {...{
        "href": "/how-to/context"
      }}>{`Real humans`}</a>{` are the ones most likely to be doing our
translations. They need to be able to see the entirety of a message to correctly
translate it. ICU messages are just a fancy way of doing string interpolation
that human translators understand. ICU messages give us an API to carefully
construct accurate messages to send to translators.`}</p>
    <p>{`Let's break this down by parts:`}</p>
    <h2 {...{
      "id": "creating-an-icu-message"
    }}>{`Creating an ICU message`}</h2>
    <p>{`Stele looks at your code in an abstract way. It does not know what your code
does, or why. All Stele knows is what it can see when webpack or babel compiles
your application. Stele does this by using the Abstract Syntax Tree that babel
provides. The babel team has a
`}<a parentName="p" {...{
        "href": "https://github.com/jamiebuilds/babel-handbook/blob/master/translations/en/plugin-handbook.md#toc-introduction"
      }}>{`great guide on ASTs here.`}</a></p>
    <p>{`I am writing this guide without the requirement of knowing more about ASTs
however. Also, here we are only going to cover message extraction, not
replacement.`}</p>
    <h3 {...{
      "id": "steps"
    }}>{`Steps`}</h3>
    <ol>
      <li parentName="ol">{`Turn Code in to an AST`}</li>
      <li parentName="ol">{`Filter down to only translatable JSX calls.`}</li>
      <li parentName="ol">{`Go through children and turn them in to corresponding ICU string.`}</li>
      <li parentName="ol">{`Extract out context from JSX call`}</li>
    </ol>
    <h3 {...{
      "id": "a-simple-starting-point"
    }}>{`A simple starting point`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<p intl={locale}>We are having a sale all day today!</p>
<p>We don't need to translate this</p>
`}</code></pre>
    <p>{`Once you mark this `}<inlineCode parentName="p">{`JSXElement`}</inlineCode>{` as something translatable Stele gets to work.
The first thing it does is convert your code to babel's AST, which very
abstractly looks something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression([ObjectMemberExpression(StringLiteral('intl'), Identifier('locale'))]),
    children: [StringLiteral('We are having a sale all day today!')]
}, {
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression(),
    children: [StringLiteral("We don't need to translate this")]
}
`}</code></pre>
    <p>{`Now our abstract syntax tree gives us something we can easily iterate and look
through. The first thing Stele does is try to find all the JSX tags in your
file. Once it does that it filters them down to only those who have your
`}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` configuration for what is to be internationalized. Say that is all
JSXElements with a prop called `}<inlineCode parentName="p">{`intl`}</inlineCode>{` that reduces our list to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression([ObjectMemberExpression(StringLiteral('intl'), null)]),
    children: [StringLiteral('We are having a sale all day today!')]
}
`}</code></pre>
    <p>{`Steps (1) and (2) are now complete!`}</p>
    <p>{`For Step (3) Stele basically just has a fancy logic statement that looks like
this in pseudocode:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const icuMessage = jsxElement.children.reduce((message, child) => {
    // if the child is a string, just add it to the message
    if (isStringLiteral(child)) {
        message = message + child.value
    }
    // other if statements
    return message
}, '')
`}</code></pre>
    <p>{`So in our simple case above we create the message
`}<inlineCode parentName="p">{`We are having a sale all day today!`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "handling-arguments"
    }}>{`Handling arguments`}</h3>
    <p>{`Let's say we augment our original statement a little bit to have a sale on
something specific:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<p intl={locale}>We are having a sale all day today on {category}!</p>
`}</code></pre>
    <p>{`Getting through step (2) above leaves us with something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression([ObjectMemberExpression(StringLiteral('intl'), Identifier('locale'))]),
    children: [StringLiteral('We are having a sale all day today '), Identifier('category'), StringLiteral('!')]
}
`}</code></pre>
    <p>{`Now our original reduce method needs to be able to handle `}<inlineCode parentName="p">{`Identifiers`}</inlineCode>{` and not
just `}<inlineCode parentName="p">{`StringLiterals`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const icuMessage = jsxElement.children.reduce((message, child) => {
    // if the child is a string, just add it to the message
    if (isStringLiteral(child)) {
        message = message + child.value
    }
    if (isIdentifier(child)) {
        message = message + \`{\${child.name}}\`
    }
    // other if statements
    return message
}, '')
`}</code></pre>
    <p>{`Now what we do is for an argument we create `}<inlineCode parentName="p">{`{category}`}</inlineCode>{` and append it to the
string. So in the loop it looks like this:`}</p>
    <ol>
      <li parentName="ol">{`''`}</li>
      <li parentName="ol">{`StringLiteral('We are having a sale all day today ') -> 'We are having a sale
all day today '`}</li>
      <li parentName="ol">{`Identifier('category') -> 'We are having a sale all day today {category}'`}</li>
      <li parentName="ol">{`StringLiteral('!') -> 'We are having a sale all day today {category}!'`}</li>
    </ol>
    <h3 {...{
      "id": "can-stele-infer-the-value-of-a-variable"
    }}>{`Can Stele infer the value of a variable?`}</h3>
    <p>{`Let's say I had a really long string and I wanted to substitute part of it out I
could do something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const duration = \`We are having a sale all day today on \${category}!\`

<p intl={locale}>{duration}</p>
`}</code></pre>
    <p>{`In this case does Stele know the full string is
`}<inlineCode parentName="p">{`We are having a sale all day today on {category}!`}</inlineCode>{`?`}</p>
    <p>{`The answer is no, stele would produce the message:`}</p>
    <pre><code parentName="pre" {...{}}>{`{duration}
`}</code></pre>
    <p>{`To figure out why let's look at what Stele sees:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression([ObjectMemberExpression(StringLiteral('intl'), Identifier('locale'))]),
    children: [Identifier('duration')]
}
`}</code></pre>
    <p>{`Because Stele first finds JSX tags, it does not get the entire scope of the
application. Therefore, Stele has no idea where `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is defined or what
possible values it can have. The only information Stele has is that there is an
identifier named `}<inlineCode parentName="p">{`duration`}</inlineCode>{` in it's children. Babel does not send along
information about where that value is defined, or what value it could possibly
have.`}</p>
    <h3 {...{
      "id": "stele-components"
    }}>{`Stele Components`}</h3>
    <p>{`Let's say we wanted to add a `}<a parentName="p" {...{
        "href": "/components/plural"
      }}>{`plural`}</a>{` to our message. Let's
say we wanted to have a sale on multiple categories:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<p intl={locale}>
    We are having a sale all day today on{' '}
    <Plural value={numCategories} one="something special" other="# things" />!
</p>
`}</code></pre>
    <p>{`This gives Stele the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const ast = {
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression([
        ObjectMemberExpression(StringLiteral('intl'), Identifier('locale')),
    ]),
    children: [
        StringLiteral('We are having a sale all day today '),
        JSXElement(
            Identifier('Plural'),
            ObjectExpression(
                MemberExpression(StringLiteral('one'), StringLiteral('something special'))
                MemberExpression(StringLiteral('other'), StringLiteral('# things'))
            )
        )
        StringLiteral('!'),
    ],
}
`}</code></pre>
    <p>{`Now we need to do a little updating of the reducer function`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const icuMessage = jsxElement.children.reduce((message, child) => {
    /* stuff from above... */

    if (isJSXElement(child)) {
        // look inside child.openingTag if that matches a stele element continue
        if (isSteleElement(child)) {
            if (child.openingTag.name === 'Plural') {
                return handlePlural(child)
            }
        }
    }
    // other if statements
    return message
}, '')
`}</code></pre>
    <p>{`I won't put the precise logic for how the plural element specifically is
handled. But basically we just go through the props construct an ICU plural
message from that. Which would look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`We are having a sale all day today on {
    numCategories,
    plural,
    one {something special}
    other {# things}
}!
`}</code></pre>
    <p>{`And Step 3 is done!`}</p>
    <h3 {...{
      "id": "handling-nesting"
    }}>{`Handling nesting`}</h3>
    <p>{`Let's say we wanted our plural text to link to our sale page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<p intl={locale}>
    We are having a sale all day today on{' '}
    <Link to="/on-sale">
        <Plural
            value={numCategories}
            one="something special"
            other="# things"
        />
        !
    </Link>
</p>
`}</code></pre>
    <p>{`Fortunately because we use Stele we can use the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` from react-router or your
design system easily!`}</p>
    <p>{`What does Stele see with this?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const ast = {
    openingTag: JSXOpeningTag('p'),
    props: ObjectExpression([
        ObjectMemberExpression(StringLiteral('intl'), Identifier('locale')),
    ]),
    children: [
        StringLiteral('We are having a sale all day today '),
        JSXElement(
            Identifier('Link'),
            ObjectExpression(
                MemberExpression(StringLiteral('to'), StringLiteral('/how-to'))
            )
            JSXElement(
                Identifier('Plural'),
                ObjectExpression(
                    MemberExpression(StringLiteral('one'), StringLiteral('something special'))
                    MemberExpression(StringLiteral('other'), StringLiteral('# things'))
                )
            )
        )
        StringLiteral('!'),
    ],
}
`}</code></pre>
    <p>{`Now what we want to do, is keep the hierarchy intact. We do it with just a
little bit of magic characters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let messageCounter = 0
const extractMessage = (message, child) => {
    /* stuff from above... */

    if (isJSXElement(child)) {
        /* the same stuff above */
        if (isSteleElement(child)) {
        } else {
            messageCounter++
            message = \`<\${messageCounter}>\${jsxElement.children.reduce(
                extractMessage,
                '',
            )}</\${messageCounter}>\`
        }
    }
    // other if statements
    return message
}

const icuMessage = jsxElement.children.reduce(extractMessage, '')
`}</code></pre>
    <p><em parentName="p">{`RECURSION!`}</em>{` We basically, are repeating the same logic in the children as if it
is a new message. We also wrap it with pseudo-HTML tags: `}<inlineCode parentName="p">{`<1></1>`}</inlineCode>{`. In the end
we get a message that looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`We are having a sale all day today on <1>{
    numCategories,
    plural,
    one {something special}
    other {# things}
}!</1>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      